import React from 'react'
import { useNavigate } from 'react-router-dom';
const LogoutButton = () => {
      const navigate = useNavigate();
    const handleLogout = async () => {
      try {
        // Option 1: Send a logout request to the backend (if needed)
        // const response = await axios.post('https://www.swaptime.co/swaptime_server/user/logout'); // Adjust URL if needed
        // if (response.status === 200) {
        //   console.log('Logout successful!');
        // } else {
        //   console.error('Error logging out:', response.message);
        // }
  
        // Option 2: Simply remove token from localStorage
        localStorage.removeItem('token');
        navigate('/login'); // Redirect to login page
      } catch (error) {
        console.error('Logout error:', error);
      }
    };
  
    return (
        <div className="text-white">
            <a className="d-grid d-block btn btn-sm btn-dark" onClick={handleLogout}>
                <i className="fas fa-fw fa-sign-out-alt"></i>
                <span>Logout</span>
            </a>
        </div>
    );
  };

  export default LogoutButton;