import React from "react";
import CoursePageView from "../components/marketing/learn-python/CoursePageView";
import BakingCoursePage from "../components/marketing/learn-python/BakingCoursePageView";
import { useParams } from "react-router-dom";
import AICoursePage from "../components/marketing/learn-python/AICoursePage";

const CoursePage = () => {
    const {course} = useParams();
    return (
        course ? (
            course == 'baking'?(
                <BakingCoursePage/>
            ):course == 'artificial-intelligence'?(
                <AICoursePage/>
            ):(
                <CoursePageView />
            )
        ) : (
            <CoursePageView />
        )
    );
};

export default CoursePage;
