import React, { useEffect, useState } from 'react';
import { Button, Container, Row, Col, Card, Alert, ListGroup, Image, Carousel } from 'react-bootstrap'; // Ensure you have react-bootstrap installed
import logo from '../../../assets/images/opec10.png'; // Import the logo image
import Cookies from 'js-cookie';

// Import the images for the gallery
import img1 from '../../../assets/images/copy_images/course-page-copy/InShot_20241216_143046866.jpg';
import img2 from '../../../assets/images/copy_images/course-page-copy/InShot_20241216_143403930.jpg';
import img3 from '../../../assets/images/copy_images/course-page-copy/InShot_20241216_143857994.jpg';
import img4 from '../../../assets/images/copy_images/course-page-copy/InShot_20241218_200648872.jpg';
import img5 from '../../../assets/images/copy_images/course-page-copy/InShot_20241218_200914769.jpg';
import img6 from '../../../assets/images/copy_images/course-page-copy/InShot_20241218_201051405.jpg';
import img7 from '../../../assets/images/copy_images/course-page-copy/InShot_20241222_084839776.jpg';
import img8 from '../../../assets/images/copy_images/course-page-copy/InShot_20241222_085159826.jpg';
import img9 from '../../../assets/images/copy_images/course-page-copy/InShot_20241222_085754606.jpg';
import img10 from '../../../assets/images/copy_images/course-page-copy/InShot_20241223_162957402.jpg';
import img11 from '../../../assets/images/copy_images/course-page-copy/InShot_20241223_163150443.jpg';
import img12 from '../../../assets/images/copy_images/course-page-copy/InShot_20241223_163757357.jpg';
import PaystackSubscription from '../../paymentComponents/PaystackSubscription';

const BakingCoursePage = () => {
    const [timeLeft, setTimeLeft] = useState(600); // Initial 10 minutes in seconds
    const [discountActive, setDiscountActive] = useState(true);
    const [showNotification, setShowNotification] = useState(false);
  
    useEffect(() => {
      // Initialize the timer from cookies or set the default value
      const savedTime = Cookies.get("timeLeft");
      if (savedTime) {
        setTimeLeft(parseInt(savedTime, 10));
      } else {
        Cookies.set("timeLeft", 600, { expires: 0.5 }); // Expire in 12 hours
      }
  
      const discountExpired = Cookies.get("discountExpired");
      if (discountExpired === "true") {
        setDiscountActive(false); // Disable discount if already expired
      }
    }, []);
  
    useEffect(() => {
      // Manage the countdown timer
      let timer;
      if (timeLeft > 0 && discountActive) {
        timer = setInterval(() => {
          setTimeLeft((prevTime) => {
            const newTime = prevTime - 1;
            Cookies.set("timeLeft", newTime, { expires: 0.5 }); // Update cookie
            return newTime;
          });
        }, 1000);
      } else if (timeLeft === 0 && discountActive) {
        // Handle when the timer reaches zero
        setDiscountActive(false);
        Cookies.set("discountExpired", "true");
        Cookies.remove("timeLeft"); // Clear time cookie after expiration
      }
  
      return () => clearInterval(timer); // Cleanup timer on unmount
    }, [timeLeft, discountActive]);
  
    const formatTime = (timeInSeconds) => {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;
      return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    };
  
    const originalPrice = 25000; // Original price in Naira
    const discountedPrice = 5000; // Discounted price in Naira
    const price = discountActive ? discountedPrice : originalPrice;
  
    const handleSuccess = (response) => {
      console.log("Payment Successful", response);
    };
  
    const handleFailure = (error) => {
      console.log("Payment Failed", error);
    };
     
  return (
    <Container className="bg-dark text-white py-1">
      <Row className="my-1">
        {/* Logo */}
        <Col md={12} className="text-center">
          <center><Image src={logo} alt="Opecstat Logo" width={200} height={200} /></center>
        </Col>
      </Row>

      <Row className="my-5">
        {/* Title and Description */}
        <Col md={12} className="text-center">
          <h1 className="display-4 font-weight-bold text-light">
            Master the Art of Baking <br/><small className='fs-4'>Learn to Bake Perfect Cakes and Avoid Common Mistakes</small>
          </h1>
          <p className="lead">
            Join our comprehensive baking course and learn to make delicious cakes, from Citrus Pond Cake to Red Velvet Cake, while mastering the art of troubleshooting 18 common baking mistakes.
          </p>
        </Col>
      </Row>

      {/* Discount Timer */}
      {discountActive && (
        <Row>
          <Col md={12} className="text-center">
            <Alert variant="success">
              <h4>Limited Time Offer!<br/> <b>Discount Ends In:</b></h4>
              <h2>{formatTime(timeLeft)}</h2>
            </Alert>
          </Col>
        </Row>
      )}

      <Row>
        {/* Course Price and Enroll Button */}
        <Col md={6}>
          <Card className="bg-secondary text-white shadow-lg">
            <Card.Body>
              <h3 className="text-light text-center">{discountActive ? 'Discount' : 'Course'} Price: ₦{discountActive ? '5,000' : '25,000'}</h3>
              <p className="text-center">
                {discountActive ? (
                  <span>
                    <big className='fw-bold'>Original Price: ₦25,000</big> <br/> Now <b>₦5,000</b>! Hurry, the offer ends in <b>{formatTime(timeLeft)}</b>.
                  </span>
                ) : (
                  <span>The discount has ended. The course is now back to ₦25,000.</span>
                )}
              </p>
              <div className="text-center">
                <PaystackSubscription
                    prefilledEmail=""
                    prefilledName=""
                    userId=""
                    amount={price}
                    onSuccess={handleSuccess}
                    onFailure={handleFailure}
                    buttonDisplayText="Enroll Now"
                    btnColor = "info"
                    payCode={90}
                    courseId='66e6f978c1eda0933df589b2'
                />
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Course Features */}
        <Col md={6}>
          <Card className="bg-secondary text-white shadow-lg">
            <Card.Body>
              <h3 className="text-light">What You'll Learn:</h3>
              <ListGroup variant="flush">
                <ListGroup.Item className="bg-secondary border-0 text-light">How to bake a Citrus Pond Cake that bursts with zesty flavors.</ListGroup.Item>
                <ListGroup.Item className="bg-secondary border-0 text-light">The secrets to making a moist and flavorful Banana Cake.</ListGroup.Item>
                <ListGroup.Item className="bg-secondary border-0 text-light">Creating an indulgent Orange Chocolate Cake.</ListGroup.Item>
                <ListGroup.Item className="bg-secondary border-0 text-light">Mastering the elegant Red Velvet Cake.</ListGroup.Item>
                <ListGroup.Item className="bg-secondary border-0 text-light">Perfecting a rich and creamy Coconut Cake.</ListGroup.Item>
                <ListGroup.Item className="bg-secondary border-0 text-light">Troubleshooting 18 common baking mistakes and their solutions.</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
      {/* Second What You Will Learn List */}
      <Col md={12} className="my-5">
        <h3 className="text-center text-light"><b>What You'll Learn (Continued)</b></h3>
        <Row>
          <Col md={6}>
            <ul className="list-group list-group-flush">
              <li className="list-group-item bg-dark text-light">You will receive Six(6) exclusive baking recipe books worth ₦30,000 absolutely FREE.</li>
              <li className="list-group-item bg-dark text-light">You will learn professional cake decoration techniques, from fondant art to buttercream mastery.</li>
              <li className="list-group-item bg-dark text-light">You will gain access to our vibrant community of bakers and alumni excelling in the baking industry.</li>
              <li className="list-group-item bg-dark text-light">You will understand the science of baking to perfect your recipes every time.</li>
            </ul>
          </Col>
          <Col md={6}>
            <ul className="list-group list-group-flush">
              <li className="list-group-item bg-dark text-light">You will master the art of baking bread, pastries, and desserts to a professional standard.</li>
              <li className="list-group-item bg-dark text-light">You will be trained in food safety and hygiene practices essential for a successful baking career.</li>
              <li className="list-group-item bg-dark text-light">You will learn how to cost your products and set up your baking business for profitability.</li>
              <li className="list-group-item bg-dark text-light">Upon course completion, you will receive a certificate recognized by leading culinary institutions.</li>
            </ul>
          </Col>
        </Row>
      </Col>
    </Row>


      <Row className="my-5">
        {/* Opecstat Background and Testimonials */}
        <Col md={12} className="text-center">
          <h2>Why Choose Opecstat?</h2>
          <p>
          Opecstat has successfully trained numerous students who are now thriving in their baking careers. Our goal is not just to teach you how to bake but to help you turn your passion into a successful career in the culinary world. We go beyond basic baking tutorials by providing mentorship, hands-on projects, and guidance to ensure you're fully prepared to excel in the baking industry.
          </p>
        </Col>
      </Row>
      

      {/* Gallery Section with Carousel */}
      <Row className="my-5">
            <Col md={12} className="text-center">
                <h2 className="text-light">Our Gallery</h2>
                <Carousel>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img1}
                    alt="Gallery Image 1"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 1</h3>
                    <p>Description of image 1.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img2}
                    alt="Gallery Image 2"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 2</h3>
                    <p>Description of image 2.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img3}
                    alt="Gallery Image 3"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 3</h3>
                    <p>Description of image 3.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                {/*<Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img7}
                    alt="Gallery Image 7"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 7</h3>
                    <p>Description of image 7.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img8}
                    alt="Gallery Image 8"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 8</h3>
                    <p>Description of image 8.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img9}
                    alt="Gallery Image 9"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 9</h3>
                    <p>Description of image 9.</p>
                    </Carousel.Caption>
                </Carousel.Item>*/}
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img10}
                    alt="Gallery Image 10"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 10</h3>
                    <p>Description of image 10.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img11}
                    alt="Gallery Image 11"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 11</h3>
                    <p>Description of image 11.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img12}
                    alt="Gallery Image 12"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 12</h3>
                    <p>Description of image 12.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                </Carousel>
            </Col>
        </Row>

        <Row>
            {/* Course Price and Enroll Button */}
            <Col md={12}>
            <Card className="bg-secondary text-white shadow-lg">
                <Card.Body>
                <h3 className="text-light text-center">{discountActive ? 'Discount' : 'Course'}: ₦{discountActive ? '5,000' : '25,000'}</h3>
                <p className="text-center">
                    {discountActive ? (
                    <span>
                        <b>Original Price: ₦25,000</b> <br/> Now <b>₦5,000</b>! Hurry, the offer ends in <b>{formatTime(timeLeft)}</b>.
                    </span>
                    ) : (
                    <span>The discount has ended. The course is now back to ₦25,000.</span>
                    )}
                </p>
                <div className="text-center">
                    <PaystackSubscription
                    prefilledEmail=""
                    prefilledName=""
                    userId=""
                    amount={price}
                    onSuccess={handleSuccess}
                    onFailure={handleFailure}
                    buttonDisplayText="Enroll Now"
                    btnColor = "info"
                    payCode={90}
                    courseId='66e6f978c1eda0933df589b2'
                    />
                </div>
                </Card.Body>
            </Card>
            </Col>
        </Row>
    </Container>
  );
};

export default BakingCoursePage;
