import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/opec10.png';
import '../../assets/styles/font_style.css';

const Navbar2 = () => {
    const [navLinkCollapsed, setNavLinkCollapsed] = useState(true);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isHovered, setIsHovered] = useState(false)
    const [isHovered2, setIsHovered2] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const linkHidden = screenWidth < 780 && navLinkCollapsed ? 'd-none' : 'mt-1 mb-1';
    const style = {
        brandBrownButton:{
            backgroundColor: isHovered ? '#A0522D' : '#FF7518',

        }
    }
    const style2 = {
        brandBrownButton:{
            backgroundColor: isHovered2 ? '#A0522D' : '#FF7518',

        }
    }
    const handleCollapseButton = () => {
        setNavLinkCollapsed(!navLinkCollapsed);
    };

    return (
        <div>
            <header className="fixed-top">
                <nav className="navbar navbar-expand-sm" style={{fontFamily:"Helvetica"}}>
                    <div className="container-fluid">
                        <Link to="/" className="navbar-brand">
                            <img src={logo} alt="Opecstat Logo" style={{ width: '8%' }} />
                        </Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={handleCollapseButton}
                        >
                            <span className="navbar-toggler-icon bg-light text-dark rounded-sm"><i className='me-1 fa fa-bars'></i></span>
                        </button>
                        <div className={`collapse navbar-collapse ${navLinkCollapsed ? '' : 'show'}`} id="navbarNav">
                            <div className="ms-auto">
                                <Link to="/login" className={`btn text-white ${linkHidden}`} style={style.brandBrownButton} onMouseEnter={()=>{setIsHovered(true)}} onMouseLeave={()=>{setIsHovered(false)}}>
                                    Log in
                                </Link>
                                <Link to="/register/fromDirect" className={`btn text-white ${linkHidden}`} style={style2.brandBrownButton} onMouseEnter={()=>{setIsHovered2(true)}} onMouseLeave={()=>{setIsHovered2(false)}}>
                                    Register
                                </Link>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </div>
    );
}

export default Navbar2;
