import React, { useEffect, useState } from 'react';
import { Button, Container, Row, Col, Card, Alert, ListGroup, Image, Carousel } from 'react-bootstrap';
import logo from '../../../assets/images/opec10.png'; // Import the logo image
import Cookies from 'js-cookie';

// Import the images for the gallery
import img1 from '../../../assets/images/copy_images/course-page-copy/InShot_20241216_143046866.jpg';
import img2 from '../../../assets/images/copy_images/course-page-copy/InShot_20241216_143403930.jpg';
import img3 from '../../../assets/images/copy_images/course-page-copy/InShot_20241216_143857994.jpg';
import img4 from '../../../assets/images/copy_images/course-page-copy/InShot_20241218_200648872.jpg';
import img5 from '../../../assets/images/copy_images/course-page-copy/InShot_20241218_200914769.jpg';
import img6 from '../../../assets/images/copy_images/course-page-copy/InShot_20241218_201051405.jpg';
import img7 from '../../../assets/images/copy_images/course-page-copy/InShot_20241222_084839776.jpg';
import img8 from '../../../assets/images/copy_images/course-page-copy/InShot_20241222_085159826.jpg';
import img9 from '../../../assets/images/copy_images/course-page-copy/InShot_20241222_085754606.jpg';
import img10 from '../../../assets/images/copy_images/course-page-copy/InShot_20241223_162957402.jpg';
import img11 from '../../../assets/images/copy_images/course-page-copy/InShot_20241223_163150443.jpg';
import img12 from '../../../assets/images/copy_images/course-page-copy/InShot_20241223_163757357.jpg';
import PaystackSubscription from '../../paymentComponents/PaystackSubscription';

const AICoursePage = () => {
    const [timeLeft, setTimeLeft] = useState(600); // Initial 10 minutes in seconds
    const [discountActive, setDiscountActive] = useState(true);
    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
        const savedTime = Cookies.get("timeLeft");
        if (savedTime) {
            setTimeLeft(parseInt(savedTime, 10));
        } else {
            Cookies.set("timeLeft", 600, { expires: 0.5 }); // Expire in 12 hours
        }

        const discountExpired = Cookies.get("discountExpired");
        if (discountExpired === "true") {
            setDiscountActive(false); // Disable discount if already expired
        }
    }, []);

    useEffect(() => {
        let timer;
        if (timeLeft > 0 && discountActive) {
            timer = setInterval(() => {
                setTimeLeft((prevTime) => {
                    const newTime = prevTime - 1;
                    Cookies.set("timeLeft", newTime, { expires: 0.5 });
                    return newTime;
                });
            }, 1000);
        } else if (timeLeft === 0 && discountActive) {
            setDiscountActive(false);
            Cookies.set("discountExpired", "true");
            Cookies.remove("timeLeft");
        }

        return () => clearInterval(timer);
    }, [timeLeft, discountActive]);

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    };

    const originalPrice = 78000; // Original price in Naira
    const discountedPrice = 7000; // Discounted price in Naira
    const price = discountActive ? discountedPrice : originalPrice;

    const handleSuccess = (response) => {
        console.log("Payment Successful", response);
    };

    const handleFailure = (error) => {
        console.log("Payment Failed", error);
    };

    return (
        <Container className="bg-dark text-white py-1">
            <Row className="my-1">
                {/* Logo */}
                <Col md={12} className="text-center">
                    <center><Image src={logo} alt="Opecstat Logo" width={200} height={200} /></center>
                </Col>
            </Row>

            <Row className="my-5">
                {/* Title and Description */}
                <Col md={12} className="text-center">
                    <h1 className="display-4 font-weight-bold text-light">
                        Master Artificial Intelligence with Python's Pytorch <br/><small className='fs-4'>Build Deep Learning Models with Pytorch</small>
                    </h1>
                    <p className="lead">
                        Join our comprehensive course and learn to build powerful AI models, from image recognition to natural language processing, all using Python's Pytorch framework.
                    </p>
                </Col>
            </Row>

            {/* Discount Timer */}
            {discountActive && (
                <Row>
                    <Col md={12} className="text-center">
                        <Alert variant="success">
                            <h4>Limited Time Offer!<br/> <b>Discount Ends In:</b></h4>
                            <h2>{formatTime(timeLeft)}</h2>
                        </Alert>
                    </Col>
                </Row>
            )}

            <Row>
                {/* Course Price and Enroll Button */}
                <Col md={6}>
                    <Card className="bg-secondary text-white shadow-lg">
                        <Card.Body>
                            <h3 className="text-light text-center">{discountActive ? 'Discount' : 'Course'} Price: ₦{discountActive ? '7,000' : '78,000'}</h3>
                            <p className="text-center">
                                {discountActive ? (
                                    <span>
                                        <big className='fw-bold'>Original Price: ₦78,000</big> <br/> Now <b>₦7,000</b>! Hurry, the offer ends in <b>{formatTime(timeLeft)}</b>.
                                    </span>
                                ) : (
                                    <span>The discount has ended. The course is now back to ₦78,000.</span>
                                )}
                            </p>
                            <div className="text-center">
                                <PaystackSubscription
                                    prefilledEmail=""
                                    prefilledName=""
                                    userId=""
                                    amount={price}
                                    onSuccess={handleSuccess}
                                    onFailure={handleFailure}
                                    buttonDisplayText="Enroll Now"
                                    btnColor = "info"
                                    payCode={90}
                                    courseId='66e6f9cec1eda0933df58d76'
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Course Features */}
                <Col md={6}>
                    <Card className="bg-secondary text-white shadow-lg">
                        <Card.Body>
                            <h3 className="text-light">What You'll Learn:</h3>
                            <ListGroup variant="flush">
                                <ListGroup.Item className="bg-secondary border-0 text-light">Building Neural Networks with Pytorch</ListGroup.Item>
                                <ListGroup.Item className="bg-secondary border-0 text-light">Training Deep Learning Models</ListGroup.Item>
                                <ListGroup.Item className="bg-secondary border-0 text-light">Working with CNNs for Image Classification</ListGroup.Item>
                                <ListGroup.Item className="bg-secondary border-0 text-light">Natural Language Processing using Pytorch</ListGroup.Item>
                                <ListGroup.Item className="bg-secondary border-0 text-light">Optimizing Models for Better Performance</ListGroup.Item>
                                <ListGroup.Item className="bg-secondary border-0 text-light">Deploying AI Models for Production</ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
            {/* What You Will Learn in the AI Course */}
                <Col md={12} className="my-5">
                    <h3 className="text-center text-light"><b>What You'll Learn in the AI Course (Cont.)</b></h3>
                    <Row>
                    <Col md={6}>
                        <ul className="list-group list-group-flush">
                        <li className="list-group-item bg-dark text-light">You will be introduced to Python programming, setting up Python, and understanding key Python syntax.</li>
                        <li className="list-group-item bg-dark text-light">Learn about Python variables, comments, data types, strings, operators, and more.</li>
                        <li className="list-group-item bg-dark text-light">Understand the core Python data structures like lists, dictionaries, and how to implement control flow using if/else, while loops, and for loops.</li>
                        <li className="list-group-item bg-dark text-light">Master Python functions and classes, and build projects like a bank system with both backend and GUI using Python.</li>
                        <li className="list-group-item bg-dark text-light">Learn how to create web pages using Python and integrate your knowledge in practical projects.</li>
                        </ul>
                    </Col>
                    <Col md={6}>
                        <ul className="list-group list-group-flush">
                        <li className="list-group-item bg-dark text-light">Dive into the world of Artificial Intelligence, starting with deep learning concepts and classification techniques.</li>
                        <li className="list-group-item bg-dark text-light">Understand the fundamentals of the perceptron algorithm and learn how to apply it to logical functions like AND, OR, and XOR.</li>
                        <li className="list-group-item bg-dark text-light">Explore linear boundaries, higher dimensions, and the importance of error functions in machine learning.</li>
                        <li className="list-group-item bg-dark text-light">Gain insights into key machine learning concepts like Softmax, One-Hot Encoding, and Maximum Likelihood Estimation (MLE).</li>
                        <li className="list-group-item bg-dark text-light">Learn about logistic regression, gradient descent, and the optimization techniques that power deep learning models.</li>
                        <li className="list-group-item bg-dark text-light">Explore more advanced concepts like neural network architecture, backpropagation, and how to deal with overfitting, underfitting, and vanishing gradients.</li>
                        </ul>
                    </Col>
                    </Row>
                </Col>
            </Row>
            
            <Row>
            {/* Introduction Video Embed */}
            <Col md={12} className="text-center my-5">
                <h2>Watch Our Introduction Video</h2>
                <div className="embed-responsive embed-responsive-16by9">
                <iframe
                    className="embed-responsive-item"
                    src="https://player.vimeo.com/video/730283471" // Replace with your actual video ID
                    allowFullScreen
                    title="Course Introduction"
                ></iframe>
                </div>
            </Col>
            </Row>
            
            <Row className="my-5">
            {/* Opecstat Background and Testimonials */}
            <Col md={12} className="text-center">
                <h2>Why Choose Opecstat?</h2>
                <p>
                Opecstat has successfully trained numerous students who are now excelling in their tech careers. Our goal is not just to teach you how to code, but to help you secure a job in the tech industry. We go beyond coding tutorials and provide mentorship, real-world projects, and guidance to ensure you're ready for your next job opportunity.
                </p>
            </Col>
            </Row>{/* Gallery Section with Carousel */}
        <Row className="my-5">
            <Col md={12} className="text-center">
                <h2 className="text-light">Our Gallery</h2>
                <Carousel>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img1}
                    alt="Gallery Image 1"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 1</h3>
                    <p>Description of image 1.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img2}
                    alt="Gallery Image 2"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 2</h3>
                    <p>Description of image 2.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img3}
                    alt="Gallery Image 3"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 3</h3>
                    <p>Description of image 3.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img7}
                    alt="Gallery Image 7"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 7</h3>
                    <p>Description of image 7.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img8}
                    alt="Gallery Image 8"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 8</h3>
                    <p>Description of image 8.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img9}
                    alt="Gallery Image 9"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 9</h3>
                    <p>Description of image 9.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img10}
                    alt="Gallery Image 10"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 10</h3>
                    <p>Description of image 10.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img11}
                    alt="Gallery Image 11"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 11</h3>
                    <p>Description of image 11.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                    className="d-block w-100"
                    src={img12}
                    alt="Gallery Image 12"
                    fluid
                    />
                    {/*<Carousel.Caption>
                    <h3>Image 12</h3>
                    <p>Description of image 12.</p>
                    </Carousel.Caption>*/}
                </Carousel.Item>
                </Carousel>
            </Col>
        </Row>

        <Row>
            {/* Course Price and Enroll Button */}
            <Col md={12}>
            <Card className="bg-secondary text-white shadow-lg">
                <Card.Body>
                <h3 className="text-light text-center">{discountActive ? 'Discount' : 'Course'}: ₦{discountActive ? '5,000' : '67,000'}</h3>
                <p className="text-center">
                    {discountActive ? (
                    <span>
                        <b>Original Price: ₦78,000</b> <br/> Now <b>₦7,000</b>! Hurry, the offer ends in <b>{formatTime(timeLeft)}</b>.
                    </span>
                    ) : (
                    <span>The discount has ended. The course is now back to ₦78,000.</span>
                    )}
                </p>
                <div className="text-center">
                    <PaystackSubscription
                    prefilledEmail=""
                    prefilledName=""
                    userId=""
                    amount={price}
                    onSuccess={handleSuccess}
                    onFailure={handleFailure}
                    buttonDisplayText="Enroll Now"
                    btnColor = "info"
                    payCode={90}
                    courseId='66e6f9cec1eda0933df58d76'
                    />
                </div>
                </Card.Body>
            </Card>
            </Col>
        </Row>
        </Container>
    );
};

export default AICoursePage;
